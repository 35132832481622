import React from "react"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import header from '../../images/projects/arctic-wolf-header.png'
import hwireframe from '../../images/projects/arctic-wolf/home-wireframe.png'
import hdeveloped from '../../images/projects/arctic-wolf/home-developed.png'
import pwireframe from '../../images/projects/arctic-wolf/product-page-wireframe.png'
import pdeveloped from '../../images/projects/arctic-wolf/product-page-developed.png'

// Component Imports
import Layout from "../../layout"
import ResumeCTA from "../../components/resume-cta"

const SafeSend = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  return (
    <Layout>


      <section class="project-hero" style={{ backgroundImage: `url(${header})` }}>
      </section>


      <section class="project-intro">
        <div class="sw">
          <div class="content">
            <h1>Arctic Wolf Mini-Site Project</h1>
            <div class="body">
              <div class="left">
                <div class="item">
                  <h3>Company</h3>
                  <ul>
                    <li>Arctic Wolf | Site Launch TBD</li>
                    {/* <li>Arctic Wolf | <a href="https://www.safesend.com" target="_blank" rel="noreferrer">arcticwolf.com</a></li> */}
                  </ul>
                </div>
                <div class="item">
                  <h3>Date</h3>
                  <ul>
                    <li>April 2021 - June 2021</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Role</h3>
                  <ul>
                    <li>UX Designer</li>
                    <li>UX Researcher</li>
                    <li>Front End Developer</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Responsibilities</h3>
                  <ul>
                    <li>Lo-Fidelity Wireframing</li>
                    <li>Prototyping</li>
                    <li>Front End Development</li>
                    <li>Quality Assurance Testing</li>
                  </ul>
                </div>
                <div class="item item--last">
                  <h3>Technologies Used</h3>
                  <ul>
                    <li>Protoshare (Wireframing Tool)</li>
                    <li>Adobe Photoshop</li>
                    <li>HTML &amp; CSS/SCSS</li>
                    <li>PHP</li>
                    <li>Prepros</li>
                    <li>Github / Git</li>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div class="item">
                  <h2>Overview</h2>
                  <p>RV Wholesale Superstore (RVWSS) is a longtime client of Artonic. Operating out of Sylvania, Ohio and serving the entire US and Canada, RVWSS offers the best RV brands on the market at wholesale prices. Their website acts as an online store to capture and drive sales of RVs to people all over the country. </p>
                </div>
                <div class="item">
                  <h2>Solution</h2>
                  <p>RVWSS has numerous dedicated mini sites for RVs they offer. The purpose of these websites is to capture leads for potential sales and customers, and drive traffic to their website. In April of 2021, RVWSS approached Artonic with the desire to create a new mini site for an RV brand called Arctic Wolf. The existing RV mini sites we’re not mobile or user friendly, did a poor job of advertising the RVs they 're focused on, and were in need of a layout and design update. Because of these factors, Artonic and RVWSS decided to revamp the layout and design of the Arctic Wolf minisite with the intent of using its design as a model when updating the existing mini sites upon project completion.</p>
                </div>
                <div class="item item--goals">
                  <h2>Goals</h2>
                  <ol class="list">
                    <li><strong>1.</strong> Create a low fidelity wireframe of the home page and 10 accompanying product pages, 11 pages total. </li>
                    <li><strong>2.</strong> Create a click through prototype of the website for client and in house review.</li>
                    <li><strong>3.</strong> Ensure the mini site would drive users to the main RVWSS by including CTAs and buttons to view existing RV inventory.</li>
                    <li><strong>4.</strong> Update the form to be more user friendly and inviting to users to boost lead generation.</li>
                    <li><strong>5.</strong> Develop the mini site based on the low fidelity wireframe I created and the hi fidelity mockups a team member created using PHP, HTML, and SCSS.CSS code.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="project-section project-section--first">
        <div class="sw">
          <div class="content">
            <div className="body">
              <h2>Wireframing and Prototyping</h2>
              <p>I began this project by creating an 11 page low fidelity wireframe of the mini site using content created by a team member and approved by the client. Per the client's request, I branded the site as an Arctic Wolf site while using RVWSS fonts. I laid out the provided content based on my experience working on previous website projects and best practices of modern web design. The product pages would all follow the same outline, so I wireframed them to be identical to feel familiar to users who visited multiple product pages, and to reduce time spent on development by ensuring code could be reused during the development process.</p>
              <div className="images">
                <div className="img-container">
                  <img src={hwireframe} alt="" />
                </div>
                <div className="img-container">
                  <img src={pwireframe} alt="" />
                </div>
              </div>
            </div>            
          </div>
        </div>
      </section>


      <section class="project-section project-section--even">
        <div class="sw">
          <div class="content">
            <div className="body">
              <h2>Development</h2>
              <p>Once the wireframe and prototype had been approved both internally and by the client, I began work on developing the project. I used PHP, HTML, and SCSS code to develop the pages so that each section was its own individual component. Wherever possible I reused sections of code to reduce time in the development process. If sections had a similar layout but slight differences, I used the ‘&--class’ selector to add styling elements that matched that class name (ex. ‘class class--class2). I set my SCSS files up in a way that each individual SCSS file was imported into a main SCSS file. I used Prepros to re-compile a CSS file each time a change was made to any existing SCSS file that was being imported in the main SCSS file. Development of the site concluded in June of 2021. </p>
              <div className="images">
                <div className="img-container">
                  <img src={hdeveloped} alt="" />
                </div>
                <div className="img-container">
                  <img src={pdeveloped} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <ResumeCTA />


    </Layout>
  )
}

export default SafeSend